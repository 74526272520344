<header class="page-banner">
  <div class="container h-100">
    <div class="row h-100">

      <div class="col-lg-8 col-md-12 my-auto">
        <div class="header-content mx-auto">
          <h1 class="display-1" style="white-space: pre-line;">{{title}}</h1>
          <h5 style="white-space: pre-line;">{{subTitle}}</h5>
        </div>
      </div>

    </div>
  </div>
</header>


<div class="work-page">


  <section>

    <!-- <div class="container mb-4">
      <h2>New Zealand Couriers</h2>
    </div> -->

    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <article >
            <h2 class="slide-title">New Zealand Couriers</h2>
            <p class="slide-text">
              We have partnered with New Zealand Couriers over the last 15 years, developing a number of iterations of their website, delivering their mobile experience and finding innovative ways to offer services to their customers and streamline business processes.
            </p>
          </article>
        </div>
      </div>
    </div>

    <div class="container mb-5">
      <div class="row col-reverse middle">
        <div class="col-sm-12 col-md-6">
          <article >
            <p class="slide-text">
              Collaborating with the New Zealand Couriers marketing team has been a rewarding journey as they continuously strive to innovate in the way they deliver services online to their customers and push the company brand in the digital environment.
            </p>
          </article>
        </div>
        <div class="col-sm-12 col-md-6">
          <figure class="slide-image">
            <img src="/assets/img/work/NZC-Ecommerce.jpg" alt="New Zealand Couriers Ecommerce">
          </figure>
        </div>
      </div>
    </div>


    <ngx-slick-carousel class="carousel container" 
    #slickModal="slick-carousel" 
    [config]="slideConfig" >
<!-- 
    <div class="slide" id="nzc-slide-1" ngxSlickItem>


    </div> -->

    <div class="slide" id="nzc-slide-2" ngxSlickItem>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <figure class="slide-image">
            <img src="/assets/img/work/NZC-Network-Status.jpg" alt="New Zealand Couriers Network Status">
          </figure>
        </div>
        <div class="col-sm-12 col-md-6">
          <figure class="slide-image">
            <img src="/assets/img/work/NZC-Help.jpg" alt="New Zealand Couriers Help">
          </figure>
        </div>
      </div>
    </div>

    <div class="slide" id="nzc-slide-3" ngxSlickItem>

      <div class="row col-reverse middle">
        
        <div class="col-sm-12 col-md-6">
          <article >
            <h3 class="slide-title quote">"...agile, responsive and 100% trustworthy. (Maker) are our online brand guardians"</h3>
            <p class="slide-text quote-byline">
              <strong>Robert Levy</strong><br>National Marketing Manager<br>New Zealand Couriers
            </p>
          </article>
        </div>
        <div class="col-sm-12 col-md-6">
          <figure class="slide-image">
            <img src="/assets/img/work/NZC-Blog.jpg" alt="New Zealand Couriers Blog">
          </figure>
        </div>
      </div>
    </div>

    <!--<div class="slide" id="nzc-slide-4" ngxSlickItem>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <article >
            <p class="slide-text">
              The Quick Track tool transformed how visitors used the site and whilst providing a smart, functional solution for the mobile experience. Maker designed and developed an interface that allows users to search for their consignment straight from the homepage, overcoming the technical constraints of the different ticket types, fields and presenting the options in a compact, user friendly tool.
            </p>
          </article>
        </div>
        <div class="col-sm-12 col-md-6">
          <figure class="slide-image">
            <img src="/assets/img/work/tracktool.png" alt="atl">
          </figure>
        </div>
      </div>
    </div>-->

    <!--<div class="slide" id="nzc-slide-5" ngxSlickItem>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <article>
            <p class="slide-text">
              New Zealand Couriers e-newsletter has been a creative testbed for the brand as the design and tone have adapted and evolved online.
            </p>
          </article>
        </div>
        <div class="col-sm-12 col-md-6">
          <figure class="slide-image">
            <img src="/assets/img/work/newsletter.png" alt="atl">
          </figure>
        </div>
      </div>
    </div>-->

    </ngx-slick-carousel>



    <!-- <div class="container" id="nzc">

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <article >
            <h2 class="slide-title">New Zealand Couriers</h2>
            <p class="slide-text">
              We have partnered with New Zealand Couriers over the last 15 years, developing a number of iterations of their website, delivering their mobile experience and finding innovative ways to offer services to their customers and streamline business processes.
            </p>
          </article>
        </div>
        <div class="col-sm-12 col-md-6">
          <figure class="slide-image">
            <img src="/assets/img/work/atl.png" alt="atl">
          </figure>
        </div>
      </div>
    </div>
    <div class="arrows">
      <a href="#" (click)="preWork($event)" title="Previous" class="arrow slider-link prev"></a>
      <a href="#" (click)="nextWork($event)" title="Next" class="arrow slider-link next"></a>
      </div> -->
  </section>



  <section class="bg-light">

    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <article >
            <h2 class="slide-title">TIMG</h2>
            <p class="slide-text">
              We provide technical resource and develop bespoke solutions for TIMG, supporting their information management offering to clients, including creating a custom registration app for Pie Funds investment group.
            </p>
            <p class="slide-text">
              Pie Funds investors can now complete their registration in their own time, without paperwork through the online portal. Maker developed a web application integrating with Green ID to provide an easy to use interactive sign up process, with trusted authentication.
            </p>
          </article>
        </div>
      </div>
    </div>
  
    <div class="container mb-5">

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <figure class="slide-image">
            <img src="/assets/img/work/pie.png" alt="Pie Funds">
          </figure>
        </div>
        <div class="col-sm-12 col-md-6">
          <figure class="slide-image">
            <img src="/assets/img/work/pieapp.png" alt="Pie Funds">
          </figure>
        </div>
      </div>

    </div>

  </section>
  

  <section>

    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <article >
            <h2 class="slide-title">St Johns</h2>
            <p class="slide-text">
              The Maker team are working closely with St Johns Ambulance to support release of their internal app, facilitating registration of users for their important alarm service, streamlining the process and giving access to users quickly and from anywhere around the country.
            </p>
          </article>
        </div>
      </div>
    </div>

    <ngx-slick-carousel class="carousel container" #slickModal="slick-carousel" [config]="slideConfig">
    
      <!-- <div class="slide" id="timg-1" ngxSlickItem>
    
      
      </div> -->
    
      <div class="slide" id="stjohn-1" ngxSlickItem>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <figure class="slide-image">
              <img src="/assets/img/work/stjohn.png" alt="St John">
            </figure>
          </div>
          <div class="col-sm-12 col-md-6">
          </div>
        </div>
      </div>
    

    </ngx-slick-carousel>

    

  </section>

  <section class="bg-light">

    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <article>
            <h2 class="slide-title">Recent projects</h2>
          </article>
        </div>
      </div>
    </div>

    <ngx-slick-carousel class="carousel container" #slickModal="slick-carousel" [config]="slideConfig">
    
      <!-- <div class="slide" id="timg-1" ngxSlickItem>
    
      
      </div> -->
    
      <div class="slide" id="recent-1" ngxSlickItem>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <figure class="slide-image">
              <img src="/assets/img/work/NZC-Driving-Ahead.jpg" alt="New Zealand Couriers Driving Ahead">
            </figure>
          </div>
          <div class="col-sm-12 col-md-6">
            <figure class="slide-image">
              <img src="/assets/img/work/Election-Judgements.jpg" alt="Election Judgements">
            </figure>
          </div>
        </div>
      </div>

      <div class="slide" id="recent-2" ngxSlickItem>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <figure class="slide-image">
              <img src="/assets/img/work/Oversight.jpg" alt="Oversight">
            </figure>
          </div>
          <div class="col-sm-12 col-md-6">
            <figure class="slide-image">
              <img src="/assets/img/work/PH-Network-Status.jpg" alt="Post Haste Couriers Status Dashboard">
            </figure>
          </div>
        </div>
      </div>
    

    </ngx-slick-carousel>

    

  </section>


  <!-- <section class="slider-container container">
    <div class="slider">
      <div class="slider-wrapper flex">
          <div class="slide flex">
              <div class="slide-image slider-link prev"><img src="/assets/img/work/atl.png"><div class="overlay"></div></div>
              <div class="slide-content">
                  <div class="slide-title">LOREM IPSUM DOLOR SITE MATE, AD EST ABHORREANT</div>
                  <div class="slide-text">Lorem ipsum dolor sit amet, ad est abhorreant efficiantur, vero oporteat apeirian in vel. Et appareat electram appellantur est. Ei nec duis invenire. Cu mel ipsum laoreet, per rebum omittam ex. </div>
                
              </div>  
          </div>
          <div class="slide flex">
              <div class="slide-image slider-link next"><img src="/assets/img/work/atl.png"><div class="overlay"></div></div>
              <div class="slide-content">
                
                  <div class="slide-title">LOREM IPSUM DOLOR SITE MATE, AD EST ABHORREANT</div>
                  <div class="slide-text">Lorem ipsum dolor sit amet, ad est abhorreant efficiantur, vero oporteat apeirian in vel. Et appareat electram appellantur est. Ei nec duis invenire. Cu mel ipsum laoreet, per rebum omittam ex. </div>
                
              </div>  
          </div>  
          <div class="slide flex">
              <div class="slide-image slider-link next"><img src="https://goranvrban.com/codepen/img5.jpg"><div class="overlay"></div></div>
              <div class="slide-content">
                 
                  <div class="slide-title">LOREM IPSUM DOLOR SITE MATE, AD EST ABHORREANT</div>
                  <div class="slide-text">Lorem ipsum dolor sit amet, ad est abhorreant efficiantur, vero oporteat apeirian in vel. Et appareat electram appellantur est. Ei nec duis invenire. Cu mel ipsum laoreet, per rebum omittam ex. </div>
              
              </div>  
          </div>
              <div class="slide flex">
              <div class="slide-image slider-link next"><img src="https://goranvrban.com/codepen/img6.jpg"><div class="overlay"></div></div>
              <div class="slide-content">
               
                  <div class="slide-title">LOREM IPSUM DOLOR SITE MATE, AD EST ABHORREANT</div>
                  <div class="slide-text">Lorem ipsum dolor sit amet, ad est abhorreant efficiantur, vero oporteat apeirian in vel. Et appareat electram appellantur est. Ei nec duis invenire. Cu mel ipsum laoreet, per rebum omittam ex. </div>
               
              </div>  
          </div>
      </div>
      <div class="arrows">
      <a href="#" (click)="preWork($event)" title="Previous" class="arrow slider-link prev"></a>
      <a href="#" (click)="nextWork($event)" title="Next" class="arrow slider-link next"></a>
      </div>
      </div>
      
  </section> -->


  

  


  <!-- <section *ngFor="let content of workContents; let i = index;" [ngClass]="{'bg-light': i%2 != 0}">
    <div class="container fade" id="ani{{i}}">

      <div class="row" [ngClass]="{'col-reverse': i%2 != 0}">
        <div class="col-sm-12 col-md-6">
          <article>
            <h2>{{content.company}}</h2>
            <p *ngFor="let p of content.showcases[0].p">{{p}}</p>
          </article>
        </div>
        <div class="col-sm-12 col-md-6">
          <figure>
            <img [src]="content.showcases[0].image" alt="showcases">
          </figure>
        </div>
      </div>
    </div>
  </section> -->

  <!-- <section>
    <div class="container" id="nzc">

      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-8">
          <article>
            <h2>New Zealand Couriers</h2>
            <p>
              We have partnered with New Zealand Couriers over the last 15 years, developing a number of iterations of their website, delivering their mobile experience and finding innovative ways to offer services to their customers and streamline business processes.
            </p>
          </article>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
          <figure>
            <img src="https://via.placeholder.com/300x180.png/ebebeb?text=Showcase+Grapic+here" alt="">
          </figure>
        </div>
      </div>
    </div>
  </section>


  <section class="bg-light">
    <div class="container" id="timg">
      <div class="row col-reverse">
        <div class="col-sm-12 col-md-6 col-lg-4">
          <figure>
            <img src="https://via.placeholder.com/300x180.png/ebebeb?text=Showcase+Grapic+here" alt="">
          </figure>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-8">
          <article>
            <h2>TIMG</h2>
            <p>
              We provide technical resource and develop bespoke solutions for TIMG, supporting their information management offering to clients, including creating a custom registration app for Pie Funds investment group.
            </p>
          </article>
        </div>
      </div>
    </div>
  </section> -->




 
  <section class="partner-section bg-white">
    <div class="container fade" id="partner">
      <!--<h2>More projects</h2>-->
      <p>
        Our approach to projects is to learn about your specific context and employ the process and technologies that will deliver for you. We love to innovate and have been lucky to work with people who have presented us unique challenges along the way.
      </p>
      <div class="partner-grid row center-xs">
        <a href="//www.piefunds.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg pie"></a>
        <a href="//www.nzcouriers.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg nzc"></a>
        <a href="//www.posthaste.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg ph"></a>
        
        <a href="//www.dxmail.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg dx"></a>
        <a href="//www.castleparcels.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg cp"></a>
        <a href="//www.nowcouriers.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg now"></a>
        
        <div class="col-6 col-sm-4 partner-bg timg"></div>
        <a href="//www.asa.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg asa"></a>
        <a href="//www.walkerarchitecture.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg wa"></a>
        
        <a href="//commercialapprovals.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg cab"></a>
        <div class="col-6 col-sm-4 partner-bg stj"></div>
        <a href="//voicebrandagency.com/" target="_blank" class="col-6 col-sm-4 partner-bg voice"></a>

        <a href="//www.zend.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg zend"></a>
        <div class="col-6 col-sm-4 partner-bg asb"></div>
        <a href="//www.ffp.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg d-none d-sm-block ffp"></a>
      </div>
    </div>
    <a class="btn btn-gradient blue mt-5" [routerLink]="['/contact']">
      <span class="text-gradient blue">&nbsp;Get in touch</span>
    </a>
  
  </section>
</div>