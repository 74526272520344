import { Component, OnInit } from '@angular/core';
import { gsap } from 'gsap/all';
import { Title, Meta } from '@angular/platform-browser';
import { SEO } from 'src/assets/data/SEO';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  title = 'Careers';
  subTitle = 'lorem ipsum dolor sit amet';

  constructor( titleService: Title,  metaService: Meta) { 
    titleService.setTitle(SEO.careers.titleTag);
    metaService.updateTag({name: 'description', content: SEO.careers.descriptionTag});
    metaService.updateTag({name: 'keywords', content: SEO.careers.keywordTag});
  }

  ngOnInit() {
    gsap.from('.header-content h1', .75, { opacity: 0, x: -10 });
    gsap.from('.header-content h5', .75, { opacity: 0, x: -10 , delay: .08});
    gsap.from('.team-page', .75, { opacity: 0, delay: .15});
  }

}
