import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  template: `
    <header class="page-banner full-height">
    <div class="container h-100">
        <div class="row h-100">
          <div class="col-lg-7 col-md-12 my-auto">
            <div class="header-content mx-auto">
              <h1 style="white-space: pre-line;">
                Page not found  ;( 
                </h1>
                <h5 style="white-space: pre-line;">The page you’re looking for can’t be found</h5>
                <br>
                <a class="btn btn-gradient tran" [routerLink]="['/home']">Back home</a>
            </div>
          </div>

        </div>
      </div>
  </header>
  `,
  styleUrls: []
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
