<header class="page-banner">
  <div class="container h-100">
    <div class="row h-100">

      <div class="col-lg-12 col-md-12 my-auto">
        <div class="header-content mx-auto">
          <h1 class="display-1" style="white-space: pre-line;">{{title}}</h1>
          <h3 style="white-space: pre-line;">{{subTitle}}</h3>
        </div>
      </div>

    </div>
  </div>
</header>



<div class="work-section">
  <section class="sub-section">

    <div class="portfolio-container">
      
      <div class="portfolio-item text-dark thumbnail-bg one" style="background-image: url(/assets/img/work/thumbnail-asa.jpg);" [routerLink]="['/projects/ASA']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">Advertising Standards Authority</h2>
      </div>
      <div class="portfolio-item text-dark thumbnail-bg two" style="background-image: url(/assets/img/work/thumbnail-nzc.jpg);" [routerLink]="['/projects/NZC']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">NZ Couriers</h2>
      </div>
      <div class="portfolio-item text-dark thumbnail-bg three" style="background-image: url(/assets/img/work/thumbnail-pie.jpg);" [routerLink]="['/projects/Pie']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">Pie Investments</h2>
      </div>
      <div class="portfolio-item text-dark thumbnail-bg four" style="background-image: url(/assets/img/work/thumbnail-stjohn.png);" [routerLink]="['/projects/st_johns']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">St. Johns</h2>
      </div>
      <div class="portfolio-item text-dark thumbnail-bg five" style="background-image: url(/assets/img/work/thumbnail-ph.jpg);" [routerLink]="['/projects/PH']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">Post Haste</h2>
      </div>
      <div class="portfolio-item text-dark thumbnail-bg six" style="background-image: url(/assets/img/work/thumbnail-fp.jpeg);" [routerLink]="['/projects/forms_portal']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">Forms Portal</h2>
      </div>
      <div class="portfolio-item text-dark thumbnail-bg seven" style="background-image: url(/assets/img/work/thumbnail-hg.jpeg);" [routerLink]="['/projects/highland_games']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">Hororata Highland Games</h2>
      </div>
      <div class="portfolio-item text-dark thumbnail-bg eight" style="background-image: url(/assets/img/work/thumbnail-ifes.jpeg);" [routerLink]="['/projects/IFES']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">IFES</h2>
      </div>
      <div class="portfolio-item text-dark thumbnail-bg nine" style="background-image: url(/assets/img/work/thumbnail-kimi.jpeg);" [routerLink]="['/projects/kimi_search']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">Kimi Search</h2>
      </div>
      <div class="portfolio-item text-dark thumbnail-bg ten" style="background-image: url(/assets/img/work/thumbnail-cab.jpeg);" [routerLink]="['/projects/commercial_approvals_bureau']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">Commercial Approvals Bureau</h2>
      </div>
      <div class="portfolio-item text-dark thumbnail-bg eleven" style="background-image: url(/assets/img/work/thumbnail-statustracker.jpeg);" [routerLink]="['/projects/status_tracker']">
        <h2 class="portfolio-title-text h-100 w-100 text-center">Status Tracker</h2>
      </div>
    </div>
  </section>
</div>


<div class="work-page">






 
  <section class="partner-section bg-white">
    <div class="container fade" id="partner">
      <!--<h2>More projects</h2>-->
      <p>
        Our approach to projects is to learn about your specific context and employ the process and technologies that will deliver for you. We love to innovate and have been lucky to work with people who have presented us unique challenges along the way.
      </p>
      <div class="partner-grid row center-xs">
        <a href="//www.piefunds.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg pie"></a>
        <a href="//www.nzcouriers.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg nzc"></a>
        <a href="//www.posthaste.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg ph"></a>
        
        <a href="//www.dxmail.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg dx"></a>
        <a href="//www.castleparcels.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg cp"></a>
        <a href="//www.nowcouriers.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg now"></a>
        
        <div class="col-6 col-sm-4 partner-bg timg"></div>
        <a href="//www.asa.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg asa"></a>
        <a href="//www.walkerarchitecture.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg wa"></a>
        
        <a href="//commercialapprovals.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg cab"></a>
        <div class="col-6 col-sm-4 partner-bg stj"></div>
        <a href="//voicebrandagency.com/" target="_blank" class="col-6 col-sm-4 partner-bg voice"></a>

        <a href="//www.zend.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg zend"></a>
        <div class="col-6 col-sm-4 partner-bg asb"></div>
        <a href="//www.ffp.co.nz/" target="_blank" class="col-6 col-sm-4 partner-bg d-none d-sm-block ffp"></a>
      </div>
    </div>
    <a class="btn btn-gradient blue mt-5" [routerLink]="['/contact']">
      <span class="text-gradient blue">&nbsp;Get in touch</span>
    </a>
  
  </section>
</div>