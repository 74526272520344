import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: []
})
export class NavComponent implements OnInit {

  isShowMenu = false;

  constructor() { }

  ngOnInit() {
  }

  menuToggle(){
    this.isShowMenu = !this.isShowMenu;
  }

}
