<header class="page-banner">
  <div class="container h-100">
    <div class="row h-100">

      <div class="col-lg-12 col-md-12 my-auto">
        <div class="header-content mx-auto">
          <h1 class="display-1" style="white-space: pre-line;">
            {{title}}
          </h1>
          <h3 style="white-space: pre-line;">{{subTitle}}</h3>
        </div>
      </div>

    </div>
  </div>
</header>


<div class="services-page">


  <div class="services-section">


    <section class="container pb-0">
      <div class="row">

        <div class="col-12 col-md-2 text-center">

          <div class="svg-icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 48.5 42.9" style="enable-background:new 0 0 48.5 42.9;" xml:space="preserve">
              <defs>
                <linearGradient id="mkGradient" gradientTransform="rotate(40)">
                  <stop offset="10%" stop-color="rgba(252,47,57,1)" stop-opacity ="0.9" />
                  <stop offset="20%" stop-color="rgba(123,25,100,1)" />
                  <stop offset="80%" stop-color="rgba(50,13,124,1)" />
                  <stop offset="100%" stop-color="rgba(2,5,140,1)" />
                </linearGradient>
              </defs>
              <g id="Group_4" transform="translate(1.793 0.692)">
                <g id="Path_3">
                  <path fill="url('/services#mkGradient')" d="M13,42.2l-2.7-1.4L32-0.7l2.7,1.4L13,42.2z M35.1,31.1l-1.8-2.4l9.9-7.5l-10.1-7.6l1.8-2.4l10.9,8.1c1,0.7,1.3,2,0.6,3
                  c-0.2,0.3-0.4,0.5-0.6,0.7L35.1,31.1z M44,20.6C44,20.6,44,20.6,44,20.6L44,20.6z M9.8,31.1L-0.9,23c-0.2-0.1-0.4-0.3-0.6-0.6
                  c-0.6-1-0.4-2.3,0.6-3L10,11.3l1.8,2.4L1.7,21.2l9.9,7.5L9.8,31.1z M0.9,20.6L0.9,20.6C0.9,20.6,0.9,20.6,0.9,20.6z" />
                </g>
              </g>
            </svg>
  
  
          </div>
        </div>

        <div class="col-12 col-md-10">
          <div class="text-wrap">
            <h2>Web, App & Cloud</h2>
            <p>
              At Maker we won’t consider your project in isolation and will always offer a solution that fits your budget now but is future proofed to keep evolving as you can invest.
            </p>
            <p>
              Maker combine out of the box services,cloud integrations, custom software and applications to meet your unique needs and resources.
            </p>
            <p>
              Our team work with the latest technologies and apply best practice standards and robust security measures to our projects.
            </p>
            <p>
              Whether you need a new website, a refresh of your online brand, a custom web service or suite of tools, at Maker you can receive advice and options for you to communicate to the world, engage with your audience and facilitate their interactions with you.
            </p>
          </div>
        </div>

        <!-- <div class="col-12 col-md-4 col-lg-3 text-center"></div> -->

        <!-- <div class="col-12 col-md-8 col-lg-9">
          <div class="img-container web-dev">
            <img src="assets/img/services/web1.png" alt="">
            <img src="assets/img/services/web2.png" alt="">
            <img src="assets/img/services/web3.png" alt="">
          </div>
        </div> -->


      </div>

    </section>

    <section class="pb-0">
      <div class="container">
        <div class="row">
  
          <div class="col-12 col-md-2 text-center">
            <!-- <img class="custom-software" src="/assets/img/cog.svg" alt="custom software"> -->
            <div class="svg-icon">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 48.6 48.3" style="enable-background:new 0 0 48.6 48.3;" xml:space="preserve">
                
                <g id="Path_1">
                  <path fill="url('/services#mkGradient')" d="M21.1,48.3c-0.8,0-1.6-0.3-2.2-0.9S18,46,18,45.2v-2.8c-2.3-0.8-4.4-2-6.2-3.6l-3.7,2.2c-1.5,0.9-3.4,0.4-4.3-1.1l-3.3-5.6
                c-0.4-0.7-0.5-1.6-0.3-2.4C0.5,31,1,30.4,1.7,30l3.7-2.1c-0.5-2.4-0.5-5,0-7.4l-3.6-2.1c-1.5-0.8-2-2.7-1.2-4.2l3.3-5.7
                C4.3,7.7,5,7.2,5.8,7c0.8-0.2,1.6-0.1,2.4,0.3l3.7,2.1c1.8-1.6,3.9-2.8,6.2-3.6V3.1C18,1.4,19.4,0,21.1,0h6.5
                c1.7,0,3.1,1.4,3.1,3.1v2.8c2.3,0.8,4.4,2,6.2,3.6l3.7-2.1C42,6.5,43.9,7,44.8,8.5l3.3,5.6c0.4,0.7,0.5,1.6,0.3,2.4
                c-0.2,0.8-0.7,1.5-1.5,1.9l-3.6,2.1c0.5,2.4,0.5,4.9,0,7.3l3.6,2.1c1.5,0.8,2,2.7,1.2,4.2l-3.3,5.6c-0.9,1.5-2.8,2-4.3,1.1
                l-3.6-2.1c-1.8,1.6-3.9,2.8-6.2,3.6v2.8c0,1.7-1.4,3.1-3.1,3.1H21.1z M12.2,35.1l0.8,0.8c1.9,1.9,4.3,3.2,6.9,4l1.1,0.3v5
                c0,0.1,0,0.1,0.1,0.1h6.5c0,0,0.1-0.1,0.1-0.1v-5l1.1-0.3c2.6-0.7,5-2.1,6.9-4l0.8-0.8l5.5,3.2c0.1,0,0.1,0,0.2,0l3.2-5.6
                c0,0,0-0.1-0.1-0.1l-5.5-3.2l0.3-1.1c0.7-2.7,0.7-5.5,0-8.1L39.8,19l5.6-3.2c0,0,0-0.1,0-0.1L42.2,10c0,0-0.1-0.1-0.2,0l-5.6,3.2
                l-0.8-0.8c-1.9-1.9-4.3-3.2-6.9-3.9l-1.1-0.3v-5c0-0.1,0-0.1-0.1-0.1h-6.5c0,0-0.1,0.1-0.1,0.1v5l-1.1,0.3
                c-2.6,0.7-4.9,2.1-6.8,3.9l-0.8,0.8L6.6,9.9c-0.1,0-0.1,0-0.2,0l-3.2,5.6c0,0,0,0.1,0.1,0.1L8.9,19l-0.3,1.1
                c-0.7,2.7-0.7,5.5,0,8.1l0.3,1.1l-5.6,3.3c-0.1,0-0.1,0.1-0.1,0.2l3.2,5.6c0,0,0.1,0.1,0.2,0L12.2,35.1z M24.4,32.1
                C24.4,32.1,24.4,32.1,24.4,32.1c-4.4,0-8-3.6-8-8c0-2.1,0.8-4.1,2.3-5.6c1.5-1.5,3.5-2.3,5.6-2.3c0,0,0,0,0,0c4.4,0,8,3.6,8,8
                C32.3,28.5,28.8,32.1,24.4,32.1z M24.4,29.1C24.4,29.1,24.4,29.1,24.4,29.1c2.7,0,5-2.2,5-5s-2.2-5-5-5c0,0,0,0,0,0
                c-2.7,0-5,2.2-5,5C19.4,26.9,21.6,29.1,24.4,29.1z" />
                </g>
              </svg>
            </div>
            <!-- <div class="v-middle-lg">
            </div> -->
          </div>
  
          <div class="col-12 col-md-10">
            <div class="text-wrap">
              <h2>Custom software</h2>
              <p>
                We are an agile software engineering and design team that focuses on delivering intuitive, innovative
                tailored solution to IT challenges. We use secure, stable and proven platforms.
              </p>
              <p>
                We aim to translate complex technologies into simple and functional devices that everyday users can enjoy.
              </p>
              <p>
                We love to design and build solutions especially crafted to solve your unique problems and frustrations
                and allow you freedom to work with more agility and strength.
              </p>
            </div>
          </div>
  
          <!-- <div class="col-12 col-md-4 col-lg-3 text-center"></div> -->
  
          <!-- <div class="col-12 col-md-8 col-lg-9">
            <div class="img-container custom">
              <img src="assets/img/services/cust1.png" alt="">
              <img src="assets/img/services/cust3.png" alt="">
              <img src="assets/img/services/cust2.png" alt="">
            </div>
          </div> -->
  
        </div>

      </div>

    </section>

    <section class="container">
      <div class="row">
        <div class="col-12 col-md-2 text-center">
          <!-- <img class="consult" src="/assets/img/consult.svg" alt="consult and partner"> -->
          <div class="svg-icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 49.9 51.7" style="enable-background:new 0 0 49.9 51.7;" xml:space="preserve">
              
              <g id="Group_2" transform="translate(1.5 1.5)">
                <g id="Path_2">
                  <path fill="url('/services#mkGradient')" d="M40.4,50.2l-8.3-5.4c-1.3,0.2-2.5,0.3-3.8,0.3c-0.1,0-0.1,0-0.2,0c-4.7,0-9.4-1.6-13.1-4.5l1.9-2.4
                c3.2,2.5,7.2,3.9,11.2,3.9c0,0,0.1,0,0.1,0c1.3,0,2.6-0.1,3.8-0.4l0.6-0.1l4.7,3V40h0.3l-0.2-0.3c4.9-3,7.9-8,7.9-13.1
                c0-3.9-1.6-7.7-4.4-10.4l2.1-2.1c3.4,3.3,5.3,7.8,5.3,12.5c0,5.7-3,11.2-8,14.8V50.2z M4.6,41.1V30.2c-3.8-3.3-6-8.1-6.1-13.2
                c0-10.2,9-18.5,20.1-18.5c11.1,0,20.1,8.3,20.1,18.5c0,10.2-9,18.5-20.1,18.5c-1.3,0-2.6-0.1-3.9-0.3L4.6,41.1z M18.6,1.5
                C9.2,1.5,1.5,8.4,1.5,17c0,4.4,2.1,8.6,5.5,11.4l0.6,0.5v7l6.6-3.9l0.6,0.1c1.3,0.3,2.6,0.4,3.8,0.4c0,0,0,0,0,0
                c9.4,0,17.1-6.9,17.1-15.5C35.7,8.4,28,1.5,18.6,1.5z M28.3,19.3c-1.3,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3s2.3,1,2.3,2.3
                S29.6,19.3,28.3,19.3z M28.3,16.3c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7S29,17.4,29,17S28.7,16.3,28.3,16.3z M18.6,19.3
                c-1.3,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3s2.3,1,2.3,2.3S19.9,19.3,18.6,19.3z M18.6,16.3c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7
                s0.7-0.3,0.7-0.7S19,16.3,18.6,16.3z M8.9,19.3c-1.3,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3s2.3,1,2.3,2.3S10.2,19.3,8.9,19.3z M8.9,16.3
                c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7S9.3,16.3,8.9,16.3z" />
                </g>
              </g>
            </svg>
          
          </div>
          <div class="v-middle-lg">
          </div>
        </div>
        <div class="col-12 col-md-10">
          <div class="text-wrap">
            <h2>Consult & partner</h2>
            <p>
              We enjoy ongoing relationships with our clients as we’re reliable, interested and invested in the continuing success of what you do, offering support and advice from the first meeting.
            </p>
            <p>
              We’re interested in what enthuses you and vexes you about what you do everyday. Let us look at the problem from a fresh perspective and offer you ways to innovate with information technology.
            </p>
          </div>
        </div>

        <!-- <div class="col-12 col-md-4 col-lg-3 text-center"></div> -->

        <!-- <div class="col-12 col-md-8 col-lg-9">
          <div class="img-container consult">
            <img src="assets/img/services/consult1.png" alt="">
            <img src="assets/img/services/consult2.png" alt="">
          </div>
        </div> -->

      </div>

    </section>

  </div>




  <div class="partner-section">
    <section>
      
      <div class="container">
          <h2 id="sm4">Technologies</h2>
          <div id="sm5">
            <p class="pt-4">
              Our approach is to be platform agnostic, meaning we will work in the technology we think is best suited to your projects. We have active projects ranging from static HTML builds to to modern iOS app development.
            </p>
            <p>
              We fully embrace open source technologies using mature, stable and secure platforms as the bases for all our work. Typically this might include SilverStripe, Craft or WordPress, hosted on updated cloud providers such as Amazon Web Services, but we also use other systems, frameworks and providers.
            </p>
            <p>
              As a development house, we also build custom and modular code. We often use PHP in its various frameworks, and Angular alongside other front-end visual technologies such as Bootstrap.
            </p>
            <p>
              It all comes down to what is best for your projects and requirements.
            </p>
          </div>
          <div class="partner-grid row center-xs" id="sm6">
            <a href="https://www.silverstripe.org/" target="_blank" class="col-12 col-sm-4 partner-bg ss"></a>
            <a href="https://www.shopify.co.nz/" target="_blank" class="col-12 col-sm-4 partner-bg shopify"></a>
            <a href="https://www.squarespace.com/" target="_blank" class="col-12 col-sm-4 partner-bg squarespace"></a>
            
            <a href="https://craftcms.com/" target="_blank" class="col-12 col-sm-4 partner-bg caft"></a>
            <a href="https://www.php.net/" target="_blank" class="col-12 col-sm-4 partner-bg php"></a>
            <a href="https://angular.io/" target="_blank" class="col-12 col-sm-4 partner-bg angular"></a>
            
            <a href="https://www.w3.org/html/" target="_blank" class="col-6 col-sm-4 partner-bg html d-none d-sm-block"></a>
            <a href="https://www.w3.org/Style/CSS/Overview.en.html" target="_blank" class="col-6 col-sm-4 partner-bg css d-none d-sm-block"></a>
            <a href="https://jquery.com/" target="_blank" class="col-6 col-sm-4 partner-bg jquery d-none d-sm-block"></a>

            <!-- Empty slot to push WP slot into middle -->
            <a href="" target="_blank" class="col-6 col-sm-4 partner-bg d-none d-sm-block"></a>
            <a href="https://wordpress.org/" target="_blank" class="col-6 col-sm-4 partner-bg wordpress d-none d-sm-block"></a>

          </div>
     
      </div>
    </section>



  
  
  </div>





  <section class="">
    <div class="container text-center">
      <h1 class="text-gradient blue">From frustration comes creation</h1>
      <a class="btn btn-gradient mt-4" [routerLink]="['/contact']">
        <span class="text-gradient blue">Talk to us&nbsp;</span>
      </a>
    </div>
  </section>

</div>



<!-- <section>

  <div class="sub-section container text-center" id="web-development">

    <div>
      <img src="../../../assets/img/webdev.png" alt="web development">
      <h2>Web development</h2>
      <p>
        We won’t consider your project in isolation and always offer a solution that fits your budget now but is
        future proofed to keep evolving as you can invest.
      </p>
      <p>
        We’ll combine integrations, out of the box services, custom software and applications to meet your unique
        needs and resources. We work with the latest technologies and apply best practice standards and robust
        security measures to our projects.
      </p>
      <p>
        New site, refresh, new service, custom web service, suite of tools.. provide advice and options for you to
        communicate to the world, engage with your audience and facilitate their interactions with you.
      </p>
    </div>

  </div>



</section>

<section class="bg-light">
  <div class="sub-section container text-center" id="custom-software">
    <div class="">
      <img src="/assets/img/custom-software.png" alt="web development">
      <h2>Custom software</h2>
      <p>
        We are an agile software engineering and design team that focuses on delivering intuitive, innovative tailored
        solution to IT challenges. We use secure, stable and proven platforms.
      </p>
      <p>
        We aim to translate complex technologies into simple and functional devices that everyday users can enjoy.
      </p>
      <p>
        We love to design and build solutions especially crafted to solve your unique problems and frustrations and
        allow you freedom to work with more agility and strength.
      </p>

    </div>
  </div>
</section>

<section>
  <div class="sub-section container text-center" id="consult-partner">
    <div class="">
      <img src="../../../assets/img/consult.png" alt="web development">
      <h2>Consult & partner</h2>
      <p>
        We enjoy ongoing relationships with out clients as we’re reliable and invested in the continuing success of
        what you do, offering support and advice from the first meeting.
      </p>
      <p>
        We’re interested in what enthuses you and vexes you about what you do everyday. Let us look at the problem
        from a fresh perspective and offer you ways to innovate with information technology.
      </p>

    </div>


  </div>
</section> -->


 <!-- <section>
    <div class="container text-center">

      <h1 class="mb-5">What kind of service you're lookig for?</h1>

      <div class="row">

        <div class="col-sm-12 col-md-6 col-lg-4">
          <a routerLink="./" fragment="web-development">
            <div class="card">
              <h3>Web development</h3>
              <span class="lead">
                A Sub headline lead to full details.
              </span>
            </div>
          </a>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4">
          <a routerLink="./" fragment="custom-software">
          <div class="card">
            <h3>Custom software</h3>
            <span class="lead">
              A Sub headline lead to full details.
            </span>
          </div>
          </a>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4">
            <a routerLink="./" fragment="consult-partner">
          <div class="card">
            <h3>Consult & partner</h3>
            <span class="lead">
              A Sub headline lead to full details.
            </span>
          </div>
          </a>
        </div>

      </div>
    </div>
  </section> -->