<header class="page-banner">
    <div class="container h-100">
        <div class="row h-100">
    
          <div class="col-lg-12 col-md-12 my-auto">
            <div class="header-content mx-auto">
              <h1 class="display-1" style="white-space: pre-line;">
                  {{title}}
                </h1>
                <h5 style="white-space: pre-line;">{{subTitle}}</h5>
            </div>
          </div>

        </div>
      </div>
</header>

<div class="team-page">


<section class="container">

  <div class="row">

    <div class="col-sm-12 col-md-6 col-lg-4">
      <figure>
        <img class="person-avatar" src="/assets/img/team/mark4x5.jpg" alt="team">
        <figcaption>
          <h5>Mark Hearne</h5>
          <div>Founder</div>
        </figcaption>
      </figure>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-4">
      <figure>
        <img class="person-avatar" src="/assets/img/team/sara4x5.jpg" alt="">
        <figcaption>
          <h5>Sara Hearne</h5>
          <div>Project Manager</div>
        </figcaption>
      </figure>
    </div>
    
    <div class="col-sm-12 col-md-6 col-lg-4">
     <figure>
       <img class="person-avatar" src="/assets/img/team/kelvin4x5.jpg" alt="">
        <figcaption>
          <h5>Kelvin Ang</h5>
          <div>Developer</div>
        </figcaption>
     </figure>
    </div>
    
    
    <div class="col-sm-12 col-md-6 col-lg-4">
      <figure>
        <img class="person-avatar" src="/assets/img/team/sam4x5.png" alt="">
        <figcaption>
          <h5>Sam Kilpatrick</h5>
          <div>Designer, Developer</div>
        </figcaption>
      </figure>
    </div>


    <div class="col-sm-12 col-md-6 col-lg-4">
     <figure>
       <img class="person-avatar" src="/assets/img/team/nui4x5.jpg" alt="">
        <figcaption>
          <h5>Nui Rattapon</h5>
          <div>Developer</div>
        </figcaption>
     </figure>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-4">
      <figure>
        <img class="person-avatar" src="/assets/img/team/hayden4x5.png" alt="">
         <figcaption>
           <h5>Hayden Isitt</h5>
           <div>Developer</div>
         </figcaption>
      </figure>
     </div>

    <!-- <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="blank-person text-center">
        <h4>We're hiring!</h4>
        <!//-- <h4>See we've made a space for you here</h4> --//> Re-comment this line in the future
        <p>
          We welcome fresh skills and perspectives
        </p>

        <a class="btn btn-gradient mt-4" [routerLink]="['/careers']">
          <span class="text-gradient blue">&nbsp;See vacancies</span>
        </a>
      </div>

    </div> -->

  </div>

</section>

<section class="bp-0 office" >
</section>


<!-- <section class="bg-light">
  <div class="container text-center">
    <h2 class="display-2 mb-4">Work with us</h2>
    <h5>Know your stuff?</h5>
    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur, dignissimos asperiores ab ipsum quasi repellat illum ea cum delectus adipisci.</p>
    <a class="btn btn-gradient light mt-4" [routerLink]="['/contact']">
      <span>&nbsp;Get in touch</span>
    </a>
  </div>
</section> -->


</div>
