import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { ServicesComponent } from './views/services/services.component';
import { WorkComponent } from './views/work/work.component';
import { TeamComponent } from './views/team/team.component';
// import { ContactComponent } from './views/contact/contact.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { CareersComponent } from './views/careers/careers.component';
import { ProjectsComponent } from './views/projects/projects.component';
import { WorkComponentOld } from './views/work_old/work.component';
// import { ThankyouComponent } from './views/contact/thankyou.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'work', component: WorkComponentOld },
  { path: 'team', component: TeamComponent },
  //{ path: 'contact/thankyou', component: ThankyouComponent },
  // { path: 'contact', component: ContactComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'contact', 
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)},
  // { path: 'projects/:projectName', component: ProjectsComponent},
  { path: '', redirectTo: '/home' , pathMatch: 'full'},
  { path: '**', component: PageNotFoundComponent }
];

const routerOptions: ExtraOptions = {
    useHash: false,
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
