<nav class="navbar navbar-expand-lg" id="mainNav">
    <div class="container">

        <a class="navbar-brand" [routerLink]="''" [ngClass]="{'-menu-open': isShowMenu}" aria-label="Maker">
            <img src="../assets/img/logo-lightgray.svg" alt="maker" width=150 height="60">
        </a>
        <button class="navbar-toggler hamburger-btn navbar-toggler-right" [ngClass]="{'-menu-open': isShowMenu}" type="button" (click)="menuToggle()">
            <svg class="ham hamRotate ham4" [ngClass]="{'active': isShowMenu}" viewBox="0 0 95 85"  width="60">
                <path class="line top" d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
                <path class="line middle" d="m 70,50 h -40" />
                <path class="line bottom" d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
            </svg>
        </button>

        <div class="navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto menu" [ngClass]="{'-open': isShowMenu}">
                <li class="nav-item">
                    <a class="nav-link" (click)="menuToggle()" routerLinkActive="active" [routerLink]="['/services']">Services</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="menuToggle()" routerLinkActive="active" [routerLink]="['/work']">Our Work</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="menuToggle()" routerLinkActive="active" [routerLink]="['/team']">Team</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="menuToggle()" routerLinkActive="active" [routerLink]="['/contact']">Contact</a>
                </li>
            </ul>
        </div>

    </div>
</nav>