<!-- Navigation -->
<!-- <div class="alert-container" *ngIf="showAlert == true">
  <div class="alert alert-dismissible show maker-alert sub-section container" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
      <span aria-hidden="true">&times;</span>
      <span class="sr-only">Close</span>
    </button>
    <strong>Maker are fully operational during the lockdown.</strong> We offer you our support. 
    <a [routerLink]="['/contact']">Get in touch</a>
  </div>
</div> -->


<app-nav></app-nav>


<router-outlet></router-outlet>


<app-footer></app-footer>