export const WORK_PAGE_CONTENT: IWork[] = [
  {// Company 1 NZC
    company: "New Zealand Couriers",
    showcases: [
      {//showcase 1
        headline: "",
        p: [
          "We have partnered with New Zealand Couriers over the last 15 years, developing a number of iterations of their website, delivering their mobile experience and finding innovative ways to offer services to their customers and streamline business processes."
        ],
        image: "/assets/img/work/atl.png"
      },
      {//showcase 2
        headline: "",
        p: [
          "We have partnered with New Zealand Couriers over the last 15 years, developing a number of iterations of their website, delivering their mobile experience and finding innovative ways to offer services to their customers and streamline business processes.",
          "paragraph 2"
        ],
        image: "/assets/img/work/atl.png"
      }
    ]
  },
  {// Company 2 TIMG
    company: "TIMG",
    showcases: [
      {//showcase 1
        headline: "",
        p: [
          "We provide technical resource and develop bespoke solutions for TIMG, supporting their information management offering to clients, including creating a custom registration app for Pie Funds investment group."
        ],
        image: "/assets/img/work/pie.png"
      }
    ]
  },

];

export interface IWork {
  company: string,
  showcases: Array<{ headline: string, p: string[], image: string }>
}

