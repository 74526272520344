import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { gsap } from 'gsap/all';
import { Title, Meta } from '@angular/platform-browser';
import { SEO } from 'src/assets/data/SEO';
declare var ScrollMagic: any;
// import * as ScrollMagic from 'ScrollMagic';
// useful link https://stackoverflow.com/questions/48028552/angular-2-and-scrollmagic


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  title = `Intuitive, innovative software\nand web development `;
  subTitle = `Streamlining processes & communicating stories`

  private _smController;
  
  constructor(titleService: Title, metaService: Meta) { 
    titleService.setTitle(SEO.home.titleTag);
    metaService.updateTag({name: 'description', content: SEO.home.descriptionTag});
    metaService.updateTag({name: 'keywords', content: SEO.home.keywordTag});
  }

  ngOnInit() {

  }

  ngAfterViewInit(){
    
      gsap.timeline()
       .from('.header-content h1', {duration: .6, opacity: 0, x: -20 , delay: .5})
       .from('.header-content h2', {duration: .3, opacity: 0, x: -10 }, "-=.15")
      //  .from('.services-section h2', 1, { opacity: 0, x: 0})
   

    this._smController = new ScrollMagic.Controller({globalSceneOptions: {reverse: true, triggerHook: 0.9}});


    let sectionToAnimate = ['#smSec1', '#smSec2', '#smSec3', '#sm5', '#sm6'];
    sectionToAnimate.forEach( scene => {
      new ScrollMagic.Scene({triggerElement: scene})
        .setClassToggle(`${scene}`, "fade-in") 
        //.addIndicators() // add indicators (requires plugin)
        .addTo(this._smController);
    });
  }

  ngOnDestroy(){
    if(this._smController)
      this._smController.destroy();
    
  }

  slides = [
    {img: "assets/img/partners/piefunds.svg", href: "//www.piefunds.co.nz/"},
    {img: "assets/img/partners/nzc.svg", href: "//www.nzcouriers.co.nz/"},
    {img: "assets/img/partners/posthaste.svg", href: "//www.posthaste.co.nz/"},

    {img: "assets/img/partners/dxmail.svg", href: "//www.dxmail.co.nz/"},
    {img: "assets/img/partners/cp.svg", href: "https://www.castleparcels.co.nz/"},
    {img: "assets/img/partners/now.svg", href: "https://www.nowcouriers.co.nz/"},

    {img: "assets/img/partners/timg.svg", href: "https://timg.co.nz/"},
    {img: "assets/img/partners/asa.svg", href: "https://www.asa.co.nz/"},
    {img: "assets/img/partners/wa.svg", href: "https://www.walkerarchitecture.co.nz/"},

    {img: "assets/img/partners/cab.svg", href: "https://commercialapprovals.co.nz/"},
    {img: "assets/img/partners/stjohn.svg", href: "#"},
    {img: "assets/img/partners/voice.svg", href: "https://voicebrandagency.com/"},
  ];
  slideConfig = { 
    slidesToShow: 3, 
    slidesToScroll: 1, 
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 1000,
    dots: false,
    pauseOnHover: true,
    mobileFirst: false,
    useTransform: true,
    prevArrow: this.getButton('slick-prev'),
    nextArrow: this.getButton('slick-next'),
    responsive:[
      {
         breakpoint: 589,
         arrows: false,
         fade: true,
      }
    ]
  };



  slickInit(e) {
    // console.log('slick initialized');
  }
  
  breakpoint(e) {
    // console.log('breakpoint');
    // console.log(e);
  }
  
  afterChange(e) {
    // console.log('afterChange');
  }
  
  beforeChange(e) {
    // console.log('beforeChange');
  }

  getButton(direction: 'slick-prev' | 'slick-next' ){
    return `<button class="${direction} slick-arrow">
      <svg xmlns="http://www.w3.org/2000/svg" width="12.915" height="26.62" viewBox="0 0 12.915 26.62">
        <path id="Path_13" data-name="Path 13" d="M-2644.141,3300l-9.3,11.173,9.3,11.222" transform="translate(2654.944 -3297.887)" fill="none" stroke="#b7b7b7" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      </svg>
    </button>`
  }

}




/* Slide option note */
/* 

$('.icons').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  dots: false,
  pauseOnHover: false,
  responsive: [{
    breakpoint: 992,
    settings: {
      slidesToShow: 3
    }
  }, {
    breakpoint: 500,
    settings: {
      slidesToShow: 1,
   autoplaySpeed: 1000
    }
  }]
}); 

*/