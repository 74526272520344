<header class="page-banner">
  <div class="container h-100">
    <div class="row h-100">

      <div class="col-lg-7 col-md-12 my-auto">
        <div class="header-content mx-auto">
          <h1 class="display-1" style="white-space: pre-line;">
            {{title}}
          </h1>
          <!-- <h5 style="white-space: pre-line;">{{subTitle}}</h5> -->
        </div>
      </div>

    </div>
  </div>
</header>

<div class="team-page">


  <section class="container">

    <small class="text-muted">10th August 2022</small>
    <h2>Front End Developer</h2>
    <h5>Christchurch | Permanent</h5>

    <br>

    <p>
      <b>Life’s what you make it</b>. At Maker, life involves variety and balance - we’re a multi-skilled team working on diverse digital
      projects. When you join us you’ll bring your own unique set of skills and interests. We’re excited to learn about
      you and offer you the support and opportunities you need to innovate and grow, whether through delivering for our
      broad range of clients or contributing to our in-house products.
    </p>



    <h5>The role:</h5>

    <p>
      Intermediate front end development with React or similar framework
      Working with our design, development and project management team
      Modern elevated studio with lift in Christchurch’s city centre, next to the park
      Flexible work arrangements including remote if needed
    </p>


    <h5>Core skills:</h5>
    <ul>
      <li>React.js or similar framework eg. Angular</li>
      <li>GIT</li>
      <li>HTML/CSS/Javascript/jQuery</li>
      <li>PHP</li>
    </ul>

    <h5>Bonus knowledge:</h5>

    <ul>
      <li>CI/CD tooling eg. Bitbucket Pipeline, Azure DevOps or Github Actions</li>
      <li>AWS</li>
      <li>React Native</li>
      <li>Typescript</li>
      <li>Next.js</li>
      <li>Gulp.js</li>
    </ul>

    <p>
      To apply please email us with your current CV and your thoughts on why we should consider you for this role.
    </p>

    <a class="btn btn-gradient mt-4" href="mailto:digital@maker.co.nz" target="">
      <span class="text-gradient blue">&nbsp;Email Us</span>
    </a>

  </section>

</div>