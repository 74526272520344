import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent {
  title = 'Maker Design Limited';

  showAlert = true;

  constructor(router: Router, gtmService: GoogleTagManagerService){
    // router.events.forEach(item => {
    //   if (item instanceof NavigationEnd) {
    //     const gtmTag = {
    //       event: 'page',
    //       pageName: item.url
    //     };
    //     gtmService.pushTag(gtmTag);
    //   }
    // });
  }

  closeAlert(){
    this.showAlert = false;
  }

}
