import { Component, OnInit } from '@angular/core';
import { gsap } from 'gsap/all';
import { Title, Meta } from '@angular/platform-browser';
import { SEO } from 'src/assets/data/SEO';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {


  title = 'Team';
  subTitle = 'We have been based in Christchurch New Zealand for 15 years and work with clients and collaborators throughout New Zealand and abroad';

  constructor(private titleService: Title, private metaService: Meta) { 
    titleService.setTitle(SEO.team.titleTag);
    metaService.updateTag({name: 'description', content: SEO.team.descriptionTag});
    metaService.updateTag({name: 'keywords', content: SEO.team.keywordTag});
  }

  ngOnInit() {
    gsap.from('.header-content h1', .75, { opacity: 0, x: -10 });
    gsap.from('.header-content h3', .75, { opacity: 0, x: -10 , delay: .08});
    gsap.from('.team-page', .75, { opacity: 0, delay: .15});
    
  }

}
