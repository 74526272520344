import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { gsap } from 'gsap/all';
import { SEO, WORK_PAGE_CONTENT } from 'src/assets/data';
import { Title, Meta } from '@angular/platform-browser';
declare var ScrollMagic: any;
import * as $ from 'jquery';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponentOld implements OnInit, AfterViewInit, OnDestroy {

  title = 'Work';
  subTitle = 'Evolving brands over 15 years, developing apps in finance & health, every project inspires the way we apply IT';
  workContents = WORK_PAGE_CONTENT;

  private _smController;


  slideConfig = { 
    slidesToShow: 1, 
    slidesToScroll: 1, 
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    dots: false,
    pauseOnHover: true,
    mobileFirst: false,
    useTransform: true,
    prevArrow: this.getButton('slick-prev'),
    nextArrow: this.getButton('slick-next')
  };

  constructor(titleService: Title, metaService: Meta) { 
    titleService.setTitle(SEO.work.titleTag);
    metaService.updateTag({name: 'description', content: SEO.work.descriptionTag});
    metaService.updateTag({name: 'keywords', content: SEO.work.keywordTag});
  }

  ngOnInit() {
    console.log('component init: ' + new Date().getMilliseconds());
    
  }

  ngAfterViewInit(){
    console.log('after view init: ' + new Date().getMilliseconds());

    gsap.from('.header-content h1', .75, { opacity: 0, x: -10 });
    gsap.from('.header-content h5', .75, { opacity: 0, x: -10 , delay: .08});
    gsap.from('.work-page', .75, { opacity: 0, delay: .15});






    this._smController = new ScrollMagic.Controller({globalSceneOptions: {reverse: true, triggerHook: 0.9}});

    let sectionToAnimate = ['#ani0', '#ani1', '#partner'];
    sectionToAnimate.forEach( scene => {
      new ScrollMagic.Scene({triggerElement: scene})
        .setClassToggle(`${scene}`, "fade-in") 
        // .addIndicators() // add indicators (requires plugin)
        .addTo(this._smController);
    });
  }

  preWork(e){
    e.preventDefault();
    console.log(this);
    this._animateSlide();
  }

  nextWork(e){
    e.preventDefault();
    console.log(this);
    this._animateSlide();
  }

  private _animateSlide(){
    let s           = $('.slider'),
        sWrapper    = s.find('.slider-wrapper'),
        sItem       = s.find('.slide'),
        btn         = s.find('.slider-link'),
        sWidth      = sItem.width(),
        sCount      = sItem.length,
        // slide_date  = s.find('.slide-date'),
        slide_title = s.find('.slide-title'),
        slide_text  = s.find('.slide-text'),
        // slide_more  = s.find('.slide-more'),
        slide_image = s.find('.slide-image img'),
        sTotalWidth = sCount * sWidth;

    var fromProperties = {autoAlpha:0, x:'-50', y:'-10'};
      var toProperties = {autoAlpha:0.8, x:'0', y:'0'};

      gsap.fromTo('.slide-image', 1, {autoAlpha:0, x:'50'}, {autoAlpha:1, x:'0'});
      // gsap.fromTo(slide_date, 0.4, fromProperties, toProperties);
      gsap.fromTo('.slide-title', 0.6, fromProperties, toProperties);
      gsap.fromTo('.slide-text', 0.8, fromProperties, toProperties);
      // gsap.fromTo(slide_more, 1, fromProperties, toProperties);
  }

  
  ngOnDestroy(){
    if(this._smController)
      this._smController.destroy();
  }

  getButton(direction: 'slick-prev' | 'slick-next' ){
    return `<button class="${direction} slick-arrow">
      <svg xmlns="http://www.w3.org/2000/svg" width="12.915" height="26.62" viewBox="0 0 12.915 26.62">
        <path id="Path_13" data-name="Path 13" d="M-2644.141,3300l-9.3,11.173,9.3,11.222" transform="translate(2654.944 -3297.887)" fill="none" stroke="#b7b7b7" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      </svg>
    </button>`
  }
}