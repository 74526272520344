import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';




import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './views/home/home.component';
import { ServicesComponent } from './views/services/services.component';
import { WorkComponent } from './views/work/work.component';
import { TeamComponent } from './views/team/team.component';
// import { ContactComponent } from './views/contact/contact.component';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { PageNotFoundComponent } from './page-not-found.component';
// import { ThankyouComponent } from './views/contact/thankyou.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { WorkComponentOld } from './views/work_old/work.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    WorkComponent,
    WorkComponentOld,
    TeamComponent,
    // ContactComponent,
    NavComponent,
    FooterComponent,
    PageNotFoundComponent,
    // ThankyouComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    
    SlickCarouselModule
    
  ],
  providers: [{provide: 'googleTagManagerId', useValue: 'UA-15925396-1'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
