<footer>
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="footer-nav">
          <ul>
            <!--<li><a routerLinkActive="active" [routerLink]="['/home']">Home</a></li>-->
            <li><a routerLinkActive="active" [routerLink]="['/services']">Services</a></li>
            <li><a routerLinkActive="active" [routerLink]="['/work']">Our Work</a></li>
            <li><a routerLinkActive="active" [routerLink]="['/team']">Team</a></li>
            <!--<li><a routerLinkActive="active" [routerLink]="['/careers']">Careers</a></li>-->
            <li><a routerLinkActive="active" [routerLink]="['/contact']">Contact</a></li>
          </ul>
        </div>
      </div>

      <div class="col-12">
        <div class="footer-logo-wrap">
          <a [routerLink]="''" aria-label="Maker">
            <img src="../assets/img/logo-lightgray.svg" alt="" width=110 height="40">
          </a>
          <p>&copy; Maker Design {{thisYear}}. All Rights Reserved.</p>
        </div>
      </div>


    </div>
  </div>
</footer>