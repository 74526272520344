
export const SEO = {
	home: {
		titleTag: "Maker Web Design and Software Development",
		descriptionTag:	"We are a multi-disciplined team designing and creating places and spaces.  Have a chat with us for your next project.",
		keywordTag: "maker design, web, app, print, design, development, christchurch, new zealand",
	},
	services: {
		titleTag: "Services | Maker Web Design and Software Development",
		descriptionTag:	"Connect and collaborate through beautiful user centred design.",
		keywordTag: "ours services, web, app, print, design, development, christchurch, new zealand",
	},
	work: {
		titleTag: "Work | Maker Web Design and Software Development",
		descriptionTag:	"We connect you and your users through high quality user centred design.",
		keywordTag: "work, web, app, print, design, development, christchurch, new zealand",
	},
	team: {
		titleTag: "Team | Maker Web Design and Software Development",
		descriptionTag:	"We have been based in Christchurch New Zealand for 15 years and work with clients and collaborators throughout New Zealand.",
		keywordTag: "team, web, app, print, design, development, christchurch, new zealand",
	},
	careers: {
		titleTag: "Careers | Maker Web Design and Software Development",
		descriptionTag:	"We have been based in Christchurch New Zealand for 15 years and work with clients and collaborators throughout New Zealand.",
		keywordTag: "team, web, app, print, design, development, christchurch, new zealand",
	},
	contact: {
		titleTag: "Contact us | Maker Web Design and Software Development",
		descriptionTag:	"We connect you and your users through high quality user centred design.",
		keywordTag: "contact us, web, app, print, design, development, christchurch, new zealand",
	}
};

