import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SEO } from 'src/assets/data/SEO';
import { gsap } from 'gsap/all';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {


  title = 'Services';
  subTitle = 'Connect & collaborate through beautiful user centred design';

  constructor(private titleService: Title, private metaService: Meta) { 
    titleService.setTitle(SEO.services.titleTag);
    metaService.updateTag({name: 'description', content: SEO.services.descriptionTag});
    metaService.updateTag({name: 'keywords', content: SEO.services.keywordTag});
  }

  ngOnInit() {
    //header animation
    gsap.from('.header-content h1', .75, { opacity: 0, x: -10 });
    gsap.from('.header-content h3', .75, { opacity: 0, x: -10 , delay: .08});
    //content animation
    gsap.from('.services-page', .75, { opacity: 0, delay: .15});
  }

}
